import api from '@/api/api'
import config from '@/config'

export async function getLandSurfaceTemperature(data) {
  return await api.get(
    `${config.backend}/projects/${data.projectId}/aois/${data.id}/map/lst/${data.vectorId}/lst`,
    data.payload,
  )
}

export async function getListResult(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/map/lst`, data.payload)
}

export async function getChartLandSurfaceTemperature(data) {
  return await api.get(`${config.backend}/projects/${data.projectId}/aois/${data.id}/chart/lst`, data.payload)
}

export async function getInfoLandSurfaceTemperature(data) {
  return await api.post(`${config.backend}/projects/${data.projectId}/aois/${data.id}/chart/lst-point`, data.params)
}
