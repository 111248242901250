<template>
  <v-layout class="fill-height" column>
    <v-layout class="fill-height">
      <div style="width: 340px; height: 100%; flex: none;">
        <v-card class="AOI-menu custom-card-bg-1 overflow-y-auto" height="100%" width="100%">
          <v-layout class="fill-height" column>
            <v-card class="custom-card-bg-0 overflow-x-hidden" style="text-align: center;" width="100%">
              <v-card-title class="py-3">
                AOI Information
                <v-spacer />
              </v-card-title>
              <v-card-text v-if="AOIData" class="pb-1">
                <div v-if="AOIGeojson" class="mt-2 px-1" style="position: relative;width: 100%;padding-top: 75%;">
                  <div
                    style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100% ; height: 100%; border-radius: 8px; z-index: 1"
                  >
                    <MapPreview :id="'AOI'" :geojson="AOIGeojson" />
                  </div>
                </div>
                <v-layout align-start class="pl-2 pt-2" column>
                  <div
                    v-for="(value, key) of AOIData"
                    v-show="key !== 'information' && key !== 'uuid' && value !== '' && value !== undefined"
                    :key="key"
                    style="font-size: 15px"
                  >
                    <b style="width:60px!important;" class="text-capitalize">{{ key }}:</b>
                    {{ key === 'area' ? transformArea(value) : value }}
                  </div>
                </v-layout>
              </v-card-text>
            </v-card>
            <div class="pt-3 px-5" style="flex: none; width: 100%; height: fit-content; ">
              <div v-if="AOI.weather" class="pa-2" style="height: 40px; width: 100%">
                <v-layout class="fill-height">
                  Current weather
                  <v-spacer />
                  <v-btn icon x-small @click="showWeather = !showWeather">
                    <v-icon v-if="!showWeather">mdi-chevron-down</v-icon>
                    <v-icon v-else>mdi-chevron-up</v-icon>
                  </v-btn>
                </v-layout>
              </div>
              <div v-if="AOI.weather" class="pa-2 pl-4" style="height: 100px; font-size: 20px">
                <div class="d-flex flex-row  justify-space-between align-center">
                  <div class="d-flex flex-column">
                    {{ currentCustomDate(new Date().toLocaleDateString()) }}
                    <div>{{ new Date().getHours() }}:00 - {{ new Date().getHours() + 1 }}:00</div>
                  </div>
                  <span style="font-size: 50px" class="mr-2">{{ AOI.weather.temp }}°</span>
                </div>
              </div>
              <div v-if="showWeather && AOI.weather" style="height: 160px; border-top: 2px solid black">
                <v-layout v-if="showWeather" class="pl-4 py-2" style="height: 80px">
                  <v-layout align-start column justify-center style="flex: 1;">
                    <span class="pb-2" style="color: var(--v-button-base)">
                      Wind
                    </span>
                    <span>
                      {{ AOI.weather.wind }} m/s
                      <v-icon
                        :style="{ transform: 'rotate(' + (90 - AOI.weather.wind_degree) + 'deg)' }"
                        class="ml-0"
                        size="16"
                      >
                        mdi-navigation
                      </v-icon>
                    </span>
                  </v-layout>
                  <v-layout align-start column justify-center style="flex: 1;">
                    <span class="pb-2" style="color: var(--v-button-base)">Humidity</span>
                    <span>
                      {{ AOI.weather.humidity }} %
                      <v-icon class="ml-0" size="16">
                        mdi-water
                      </v-icon>
                    </span>
                  </v-layout>
                </v-layout>
                <v-layout v-if="showWeather" align-center class="pl-4" style="height: 80px;">
                  <v-layout align-start column justify-center style="flex: 1;">
                    <span style="color: var(--v-button-base)">Precipitation</span>
                    {{ AOI.weather.precipitation }} mm
                  </v-layout>
                  <v-layout align-start column justify-center style="flex: 1;">
                    <span class="pb-2" style="color: var(--v-button-base)">Clouds</span>
                    <span>
                      {{ AOI.weather.cloud }} %
                      <v-icon class="ml-0" size="16">
                        mdi-cloud
                      </v-icon>
                    </span>
                  </v-layout>
                </v-layout>
              </div>
            </div>
            <v-spacer />

            <v-divider />
            <div class="pa-3" style="flex: none; height: 300px; width: 100%">
              <canvas id="lstPrimaryChart" height="auto" width="100%"></canvas>
            </div>
          </v-layout>
        </v-card>
      </div>
      <v-layout class="fill-height pl-4" column>
        <div class="pb-2" style="width: 100%; flex: none">
          <v-layout class="fill-height">
            <v-col ref="AOI" class="d-flex align-center pt-4 pl-0" cols="12" lg="4">
              <select2
                v-model="currentAOI"
                :items="listAOI"
                dense
                hide-details
                item-text="name"
                item-value="uuid"
                label="Select AOI"
                outlined
                placeholder="AOI"
                return-object
                @change="changeAOI"
              />
              <!--              <v-select-->
              <!--                v-model="currentAOI"-->
              <!--                :items="AOIs"-->
              <!--                :loading="loading"-->
              <!--                dense-->
              <!--                hide-details-->
              <!--                item-text="name"-->
              <!--                label="Select AOI"-->
              <!--                outlined-->
              <!--                placeholder="AOI"-->
              <!--                return-object-->
              <!--                @change="changeAOI"-->
              <!--              />-->
            </v-col>
            <v-col ref="source" class="d-flex align-center pt-4" cols="12" lg="4">
              <v-select
                v-model="source"
                :disabled="!Object.keys(service).length"
                :items="sources"
                dense
                hide-details
                item-text="name"
                label="Select source"
                outlined
                placeholder="Source"
                return-object
                @change="getImage"
              />
            </v-col>
            <v-col ref="dateRange" class="d-flex align-center pt-4" cols="12" lg="4">
              <DateRangerPicker
                v-model="dateRanger"
                dense
                hide-details
                label="Date Range"
                outlined
                placeholder="Date Range"
              />
            </v-col>
            <v-col class="align-center pt-4" cols="12" lg="4"></v-col>
          </v-layout>
        </div>
        <v-card height="100%" style="position: relative" width="100%">
          <Map
            ref="map"
            :current-layers.sync="layers"
            :isDraw="false"
            @clickGeojson="getFeatureInfo"
            :isLoading="loading"
            @currentPoint="getWithinLayer"
          />
          <div style="width: 55px; height: 280px; max-height: 90%; position: absolute; top: 10px; left: 20px">
            <div style="width: 10px; height: 100%;" v-bind:style="{ 'background-image': gradient }"></div>
            <div
              v-for="(item, index) in gradientColor"
              v-show="index % 2 === 0"
              style="position: absolute; right: 0; width: 100%; height: 1px; border: 1px solid #eaeaea; font-size: 11px; color: #eaeaea; text-align: end"
              v-bind:style="{ top: 100 - index * 10 + '%' }"
            >
              {{ item.value.toFixed(2) }}
            </div>
          </div>
          <TourGuide
            style="position: absolute; bottom: 40px; right: 125px; z-index: 2"
            ref="tourGuide"
            :color="'#e3e3e3'"
            :iconColor="'#66808d'"
            v-if="elements"
            :elements="elements"
          />
        </v-card>
        <v-card class=" d-flex custom-card-bg-1 justify-center pt-1 pr-1 pb-1 pl-2 mt-1">
          <div v-if="infoFeature && showable" style=" width: 275px; height: 100%; " class="mr-2">
            <h3 class="py-2">Data information</h3>
            <v-layout class="location mb-3">
              <v-icon size="18">mdi-map-marker</v-icon>
              <div style="font-size: 12px;">
                [{{ parseFloat(infoFeature.lng.toFixed(3)) }}, {{ parseFloat(infoFeature.lat.toFixed(3)) }}]
              </div>
            </v-layout>

            <div v-if="AOIGeojson" style="position: relative;width: 100%;padding-top: 80%;">
              <div
                style="position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100% ; height: 100%; border-radius: 8px; z-index: 1"
              >
                <MapPreview :id="'AOI'" :geojson="AOIGeojson" :point="point" />
              </div>
            </div>
          </div>
          <div class="d-flex flex-column overflow-hidden" style="flex:1; position: relative;">
            <div ref="result" class="custom-card-bg-0 d-flex align-center">
              <SlideGroup style="flex: 1;width:100%;z-index:10;" :items="data" :currentItem="image" multiple>
                <template v-slot:content="{ item, index }">
                  <div
                    class="item-date custom-card-bg-1 d-flex  align-center justify-center"
                    :class="{ selected: item.uuid == image.uuid }"
                    :key="index"
                    :id="index === 0 ? 'itemFirst' : index === data.length - 1 ? 'itemLast' : ''"
                    @click="displayImage(item)"
                    style="min-height:35px"
                  >
                    <div
                      class="d-flex flex-column justify-center align-center  my-1"
                      style="font-size: 12px; text-align: center;"
                    >
                      <span> {{ item.customDate }}</span>
                    </div>
                  </div>
                </template>
              </SlideGroup>
              <v-spacer />
              <v-btn :disabled="!lstChart" icon small @click="changeDisplayChart">
                <v-icon>{{ showable ? 'icon-chevrons_down' : 'icon-chevrons_up' }}</v-icon>
              </v-btn>
            </div>
            <v-card v-show="showable" class="mr-2  mt-2 custom-card-bg-1" style="max-height:250px">
              <canvas id="lstChart" height="auto" width="100%"></canvas>
            </v-card>
          </div>
        </v-card>
      </v-layout>
    </v-layout>
    <MakeOrder ref="makeOrder" isOnScreenDialog />
  </v-layout>
</template>

<script>
import Map from '@/components/Map'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import { mapState } from '@/store/ults'
import { getDetailAOI } from '@/api/aoi-api'
import ConvertDate from '@/utils/convertDate'
import AreaConvert from '@/utils/textArea'
import CustomIcon from '@/components/CustomIcon.vue'
import MapPreview from '@/components/MapPreview.vue'
import Chart from 'chart.js/auto'
import GradientColor from '@/utils/genGradiendColor'
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import Select2 from '@/components/Select2/Select2.vue'
import { getOrder } from '@/api/order'
import { getInfoLandSurfaceTemperature, getListResult } from '@/api/land-surface-temperature-api'
import TourGuide from '@/components/GuideTour/index.vue'
import sleep from '@/utils/sleep'
import MakeOrder from '@/views/aoi/order/MakeOrder.vue'
import SlideGroup from '@/views/object-detection/components/SlideGroup.vue'
import { image } from 'd3'
import utils from '@/utils/genUUID'
import booleanIntersects from '@turf/boolean-intersects'
import point from 'turf-point'

export default {
  name: 'LandSurfaceTemperature',
  components: {
    Select2,
    DateRangerPicker,
    MapPreview,
    CustomIcon,
    LayerControl,
    Map,
    TourGuide,
    MakeOrder,
    SlideGroup,
  },
  data() {
    return {
      AOI: {},
      showWeather: false,
      dateRanger: [],
      lstChart: undefined,
      lstPrimaryChart: undefined,
      labels: [],
      chartData: [],
      AOIData: undefined,
      AOIGeojson: undefined,
      showable: false,
      statistic: undefined,
      dataLoading: false,
      loading: false,
      layers: [],
      source: undefined,
      currentAOI: undefined,
      leftMenu: true,
      sources: [],
      service: {},
      data: [],
      result: undefined,
      changeData: [],
      infoFeature: undefined,
      gradientColor: [],
      filterStyle: [],
      firstLoad: true,
      selectedDate: undefined,
      image: undefined,
      oldDate: undefined,
      elements: undefined,
      point: undefined,
    }
  },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    gradient() {
      let colors = [...this.gradientColor].reverse()
      return 'linear-gradient(' + colors.map(val => val.color).toString() + ')'
    },
  },
  beforeDestroy() {
    if (this.$refs.tourGuide) this.$refs.tourGuide.completeTour()
  },
  async mounted() {
    await this.getListAOI()
    this.getStatistic()
    await sleep(1200)
    this.initGuide()
  },
  watch: {
    dateRanger(val) {
      this.getImage()
    },
    showable() {
      this.$refs.map.reSize()
    },
    options: {
      handler() {
        this.getData()
      },
      deep: true,
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
  },
  methods: {
    initGuide() {
      this.elements = [
        {
          ref: this.$refs.AOI,
          id: 'AOI',
          title: 'Getting Started',
          text:
            this.listAOI.length > 0
              ? 'Select an AOI to get results'
              : "You don't have any AOIs with results. Please submit an order.",
          position: 'bottom',
          isHideContinueBtn: this.listAOI.length > 0 ? undefined : true,
          buttons:
            this.listAOI.length > 0
              ? undefined
              : [
                  {
                    text: 'Submit Order',
                    action: () => {
                      this.$refs.tourGuide.completeTour()
                      this.$refs.makeOrder.openDialog(null, this.$route.meta.service)
                    },
                  },
                ],
        },
        {
          ref: this.$refs.source,
          id: 'source',
          title: 'Getting Started',
          text: 'Select a source of result',
          position: 'bottom',
        },
        {
          ref: this.$refs.dateRange,
          id: 'dateRange',
          title: 'Getting Started',
          text: 'Select date range to show result',
          position: 'bottom',
        },
        {
          ref: this.$refs.result,
          id: 'result',
          title: 'Getting Started',
          text: this.data.length > 0 ? 'Select a result to show' : 'No result found, please check your order',
          position: 'bottom',
          buttons:
            this.data.length > 0
              ? undefined
              : [
                  {
                    text: 'Check order',
                    action: () => {
                      this.$refs.tourGuide.completeTour()
                      this.$router.push({
                        name: 'orders',
                        query: {
                          aoiId: this.currentAOI.uuid,
                          service: this.service.service_id,
                          source: this.source,
                        },
                      })
                    },
                  },
                ],
        },
      ]
    },

    async changeAOI() {
      await this.getDetailAOI()
      this.getService()
    },
    async getService() {
      try {
        this.loading = true
        this.$refs.map.removeAllLayer()
        const res = await getOrder({
          projectId: this.$route.params.id,
          id: this.currentAOI.uuid,
          payload: { service_name: this.$route.meta.service },
        })
        this.service = res.data[0]
        this.dateRanger = [this.service.from_date, this.service.to_date]

        this.sources = []
        res.data.forEach(val => this.sources.push(val.image_source))
        this.source = this.sources[0]
        await this.getImage()

        this.$refs.map.submitZoom(this.currentAOI.bbox)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getImage() {
      if (!this.currentAOI) return
      try {
        this.loading = true
        this.currentDate = {}
        this.compareDate = {}
        this.chartData = {}
        this.data = []
        const res = await getListResult({
          id: this.currentAOI.uuid,
          projectId: this.$route.params.id,
          payload: {
            source: this.source,
            per_page: 'all',
            sort: 'acquired_date',
          },
        })
        this.data = res.data
        let data = {
          labels: [],
          values: [],
        }
        this.data.forEach(image => {
          image.customDate = ConvertDate.customDate(image.date)
          data.labels.push(image.acquired_date)
          data.values.push(image.average)
        })
        if (this.firstLoad && this.data.length) {
          this.result = this.data[0]
        }
        this.image = this.data[this.data.length - 1]
        await this.displayImage()

        if (!this.lstPrimaryChart) this.displayChart(data, 'lstPrimaryChart')
        else {
          this.transformData(data)
          this.updateChartData(this.chartData, 'lstPrimaryChart')
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.firstLoad = false
        this.loading = false
      }
    },
    async displayImage(item) {
      if (item) this.image = item
      this.$refs.map.removeAllLayer()
      let image = {
        id: this.image.name,
        data: this.image.tile_info,
        layerType: 'Raster_Image',
        name: this.image.name,
        tiles: [this.image.tile_info.tile_url],
        bounds: this.image.tile_info.bbox,
      }
      this.$refs.map.addImageToMap(image)
      this.$refs.map.submitZoom(this.image.tile_info.bbox)
      await this.displayAOI()
    },
    getWithinLayer(currentPoint) {
      this.$refs.map.closePopup()
      if (!booleanIntersects(point(currentPoint), this.AOI.geojson)) {
        this.$refs.map.removePopup()
      } else {
        let html = `
    <div style='width: fit-content; text-align: left;padding:10px'>
      <div style='float: left; color: black; '>
        <b>Longitude: </b>${currentPoint[0]}
      </div><br>
      <div style='float: left; color: black; '>
        <b>Latitude: </b>${currentPoint[1]}
      </div>
    </div>
  `
        this.$refs.map.displayPopup(currentPoint, html, false, false)
      }
    },
    async getDetailAOI(fieldId = this.currentAOI.uuid) {
      try {
        this.loading = true
        let res = await getDetailAOI({
          projectId: this.$route.params.id,
          id: fieldId,
          payload: { weather: true },
        })
        res.data.layerId = 'aoi_' + res.data.id
        res.data.featureCollection = {
          type: 'FeatureCollection',
          name: 'field',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        this.AOIGeojson = res.data.featureCollection
        res.data.bbox = this.currentAOI.bbox
        this.AOI = { ...res.data }
        this.loading = false
      } catch (e) {
        this.loading = false
      } finally {
      }
    },
    changeDisplayChart() {
      this.showable = !this.showable
    },
    async getFeatureInfo(point) {
      try {
        this.loading = true
        this.point = point
        try {
          const res = await getInfoLandSurfaceTemperature({
            projectId: this.$route.params.id,
            id: this.currentAOI.uuid,
            params: {
              paths: this.data.map(val => val.path),
              x: point['lng'],
              y: point['lat'],
            },
          })
          this.showable = true
          this.infoFeature = {
            lng: point['lng'],
            lat: point['lat'],
          }
          let data = {
            values: res.data,
            labels: this.data.map(val => val.date),
          }

          if (!this.lstChart) this.displayChart(data, 'lstChart')
          else {
            this.transformData(data)
            this.updateChartData(this.chartData, 'lstChart')
          }
        } catch (e) {
          console.log(e)
        } finally {
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.resetData()
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: { service_name: this.$route.meta.service },
        })
        this.currentAOI = this.AOIs[0]
        // if (this.currentAOI) await this.getService()
        // else this.firstLoad = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getStatistic() {
      try {
        this.loading = true
        this.gradientColor = []
        let colors = GradientColor.generate_colors(
          ['#800000', '#FF3D00', '#f86700', '#FFEA00', '#388E3C', '#A8EBFF', '#1976D2', '#02006c'],
          11,
        )
        const max = 50
        const min = -25

        let step = (max.toFixed(2) - min.toFixed(2)) / 10
        for (let i = 0; i < 11; i++) {
          this.gradientColor.push({
            color: colors[colors.length - i - 1].color,
            value: min + i * step,
          })
        }

        // let tile_url = res.data.tile_info.tile_url + '&color_map='
        // this.gradientColor.forEach((val, index) => {
        //   tile_url = tile_url + val.value / 1000 + ',' + val.color.replace('#', '')
        //   if (index < this.gradientColor.length - 1) tile_url = tile_url + ';'
        // })
        // this.image = {
        //   id: res.data.date_index + 'image',
        //   display: true,
        //   data: res.data.tile_info,
        //   layerType: 'Raster_Image',
        //   name: res.data.date_index,
        //   tiles: [tile_url],
        //   bounds: res.data.tile_info.bbox,
        // }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async displayAOI() {
      try {
        if (!this.currentAOI) this.AOIGeojson = undefined
        this.loading = true
        this.AOIData = {
          area: this.currentAOI.area,
          name: this.currentAOI.name,
          group: this.currentAOI.group,
        }

        this.$refs.map.addGeoJsonToMap(
          utils.getUUID(),
          {
            type: 'FeatureCollection',
            name: this.currentAOI.uuid,
            features: [
              {
                type: 'Feature',
                properties: { clickable: true },
                geometry: this.AOIGeojson.features[0].geometry,
              },
            ],
          },
          'transparent',
          'aoi_' + this.currentAOI.uuid,
          'fill',
          true,
          undefined,
          {
            'fill-color': 'transparent',
            'fill-outline-color': 'transparent',
          },
        )
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    transformArea(area, withUnit = true) {
      return AreaConvert.covertArea(area, 'ha', withUnit)
    },
    resetData() {
      this.selectedDate = undefined
      this.source = undefined
      this.changeData = []
      this.data = []
      this.result = undefined
    },
    transformData(chartInfo) {
      this.chartData = []
      this.labels = []
      this.labels = chartInfo.labels
      this.chartData.push({
        type: 'line',
        label: '',
        data: chartInfo.values,
        backgroundColor: 'blue',
        borderColor: 'red',
        borderWidth: 2,
        pointBorderWidth: 4,
        pointHitRadius: 6,
        pointBackgroundColor: 'blue',
      })
    },
    displayChart(chartInfo, chartId) {
      this.transformData(chartInfo)
      const data = {
        labels: this.labels,
        datasets: this.chartData,
      }
      let ctx = document.getElementById(chartId)
      this[chartId] = new Chart(ctx, {
        data: data,
        options: {
          onClick: (c, i) => {
            let e = i[0]
            if (!e) return
            let currentImage = this.data.find(item => item.date === data.labels[e.index])
            if (currentImage) {
              this.image = currentImage
              this.displayImage()
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          title: {
            color: '#d4d4d4',
            display: true,
            text: '',
          },
          scales: {
            x: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
            y: {
              ticks: {
                color: '#d4d4d4',
              },
              stacked: false,
              title: {
                color: '#d4d4d4',
                text: 'C',
                display: true,
              },
              grid: {
                color: '#2F3241',
                display: true,
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                return tooltipItem.yLabel
              },
            },
          },
        },
      })
    },
    updateChartData(newData, chartId) {
      this[chartId].data.datasets = newData
      this[chartId].data.labels = this.labels
      this[chartId].update()
    },
    currentCustomDate(date) {
      return ConvertDate.customDate(date)
    },
  },
}
</script>
<style scoped>
.item-date {
  cursor: pointer;
  position: relative;
  padding: 0 10px !important;
  margin: 0 2px !important;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  border-radius: 4px;
  border: 1px solid transparent !important;
  &.selected {
    color: var(--v-accent-base);
  }
  &:hover {
    border: 1px solid var(--v-primary-base);
  }
}
</style>
